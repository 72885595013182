import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/pages/partials/Dialog.vue";
import JwtService from "@/core/services/jwt.service";
import { POST } from "@/core/services/store/request.module";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: null
    },
    sampleUrl: {
      type: String,
      required: true,
      default: null
    },
    apiUrl: {
      type: String,
      required: true,
      default: null
    }
  },
  data: function data() {
    return {
      valid_import: true,
      excel_imported: false,
      loading_imported: false,
      success_message: [],
      error_message: []
    };
  },
  methods: {
    handle_file_upload: function handle_file_upload(param) {
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },
    download_sample: function download_sample() {
      var token = JwtService.getToken();
      var url = this.$apiURL + this.sampleUrl + "?token=" + token;
      window.open(url, "_target");
    },
    import_rows: function import_rows() {
      var _this = this;

      var file = _this.file;
      ClearEventBus.$emit("clear:success", true);
      ClearEventBus.$emit("clear:error", true);

      if (!file) {
        ErrorEventBus.$emit("update:error", "Please select a valid file.");
        return false;
      }

      _this.loading_imported = true;
      _this.excel_imported = false;
      _this.success_message = [];
      _this.error_message = [];
      var data = new FormData();
      data.append("excel", file);

      _this.$store.dispatch(POST, {
        url: this.apiUrl,
        data: data
      }).then(function (_ref) {
        var data = _ref.data;
        _this.success_message = data.success || [];
        _this.error_message = data.error || [];
        _this.excel_imported = true;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.loading_imported = false;
      });
    }
  },
  components: {
    Dialog: Dialog
  }
};